










































































































































































































section {
  main {
    > div {
      &:nth-child(1) {
        display: flex;
        justify-content: center;
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0;
        border-bottom: 1px solid #ececec;

        > div {
          margin: 0 50px;

          &:nth-child(1) {
            width: 400px;

            > div {
              &:nth-child(1) {
                text-align: center;

                > img {
                  width: 400px;
                  height: 400px;
                  object-fit: contain;
                }
              }

              &:nth-child(2) {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                > div {
                  width: 40px;
                  height: 40px;
                  margin: 0 10px;
                  padding: 15px;
                  background-color: #999;
                  text-align: center;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  > img {
                    max-width: 60px;
                    max-height: 60px;
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            width: 540px;
            margin-top: 10px;
            position: relative;

            > div {
              position: absolute;
              right: -25px;
              top: 130px;
              width: 6px;
              height: 100px;
              background-color: #3031AA;
            }

            > h2 {
              font-size: 30px;
              color: #000;
              font-weight: normal;
            }

            > h3 {
              font-size: 20px;
              color: #333;
              font-weight: normal;
            }

            > p {
              font-size: 16px;
              line-height: 30px;
              color: rgba(50, 50, 50, 1);
            }
          }
        }
      }

      &:nth-child(2) {
        padding: 60px 0;
        width: 1200px;
        margin: 0 auto;

        .tab-list {
          padding: 30px 0;

          .manuals {
            > ul {
              > li {
                position: relative;
                padding: 15px 50px;
                background-color: #f6f6f6;

                > a {
                  font-size: 16px;
                }

                &:hover {
                  > a {
                    color: #d9261d;

                    &:before {
                      content: "";
                      width: 6px;
                      height: 6px;
                      background: #000096;
                      position: absolute;
                      top: 25px;
                      left: 25px;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(3) {
        padding: 60px 0;
        background-color: #eee;
        text-align: center;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(2) {
            width: 1200px;
            margin: 0 auto;
            padding: 60px 0;
            text-align: left;
            position: relative;

            > i {
              position: absolute;
              display: inline-block;
              width: 18px;
              height: 40px;
              background: url("../assets/img/arrow-right-blue.png") no-repeat center;
              z-index: 10;

              &:nth-of-type(1) {
                left: -60px;
                transform: rotate(180deg);
              }

              &:nth-of-type(2) {
                right: -60px;
              }
            }

            > ul {
              display: flex;
              justify-content: center;

              > li {
                width: 380px;
                height: 360px;
                padding: 0 20px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .richhtml{
    ::v-deep img{
      width: 100%;
      height: 100%;
    }
  }
  section {
    main{
      h2 {
        font-size: 20px !important;
        line-height: 24px !important;
      }
      h3 {
        font-size: 18px !important;
      }
      >div:nth-child(1) {
        width: 100%;
        padding-bottom: 20px;
        >div:nth-child(1){
          display: none;
        }
        >div:nth-child(2){
          width: 100%;
          margin: 15px 15px 0 15px;
          >div {
            right: -10px;
            top: 90px;
          }
        }
      }
      >div:nth-child(2) {
        width: 100%;
        >.container {
          padding: 0 15px;
        }
      }
      >div:nth-child(3) {
        padding: 10px;
        >div:nth-child(2){
          width: 100%;
          padding: 10px 0;
          i:nth-of-type(1){
            left:0
          }
          i:nth-of-type(2){
            right: 0;
          }
          >ul {
            width: 100%;
            >li{
              width: 100%;
            }
            >li:nth-child(3),:nth-child(2){
              display: none;
            }
          }
        }
      }
    }
  }
}
