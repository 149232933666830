



































.container {
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px #eee solid;
  overflow: hidden;
  display: inline-block;
  height: 360px;

  &:hover {
    > ul {
      transform: translate(0, 0);
    }

    > div:nth-child(1) {

      > img {
        transform: scale(1.1, 1.1);
      }
    }

    > div:nth-child(2) {
      transform: translate(0, 100px);
    }
  }

  > div:nth-child(1) {
    position: absolute;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    z-index: 100;

    > img {
      position: relative;
      height: 225px;
      object-fit: contain;
      transition: transform 0.3s linear;
      
    }
  }

  > div:nth-child(2) {
    box-sizing: border-box;
    padding: 20px 30px;
    color: #333;
    transition: transform 0.3s linear;
    border-top: 1px #eee solid;
    position: absolute;
    width: 100%;
    bottom: 0;

    > div {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:nth-child(1) {
        font-size: 20px;
      }

      &:nth-child(2) {
        font-size: 16px;
      }
    }
  }

  > ul {
    box-sizing: border-box;
    position: absolute;
    color: #fff;
    background-color: #000096;
    padding: 15px 25px;
    bottom: 0;
    width: 100%;
    transition: transform 0.3s linear;
    transform: translate(0, 160px);
    > li {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:first-child {
        font-size: 20px;
        margin-bottom: 5px;
      }

      &:not(&:first-child) {
        position: relative;
        font-size: 14px;
        padding-left: 20px;

        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          top: 7px;
          left: 2px;
        }
      }
    }
  }
}
